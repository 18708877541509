import { useNavigate } from "react-router-dom";
import { User } from "firebase/auth";
import { CS1_URL, CS2_URL, CS3_URL, SD_URL } from "../../data/classes";

interface IProps {
    user: User | null | undefined;
    isAdmin: boolean;
}

const Home: React.FunctionComponent<IProps> = (props: IProps) => {

    const navigate = useNavigate();

    return (
        <div className="home">

            {/* Welcome to ShadowDarklings */}
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-3">
                        <p className="text-center mb-0 welcome">Welcome to <b>ShadowDarklings</b>, a character creator for the <a href="https://www.thearcanelibrary.com/pages/shadowdark" target="_blank" rel="noreferrer" className="noUnderline">ShadowDark</a> roleplaying game.</p>

                        {!props.user &&

                            <>
                                <div className="d-none d-sm-block">
                                    <div className="text-center mt-3">
                                        <button className="btn btn-dark btn-lg me-2 mb-0" onClick={() => navigate('/registerAccount')}>Register</button>
                                        <button className="btn btn-dark me-2 btn-lg mb-0" onClick={() => navigate('/login')}>Log In</button>
                                        <button className="btn btn-dark me-2 btn-lg mb-0" onClick={() => navigate('/create')}>Create a Character</button>
                                    </div>
                                </div>

                                <div className="d-block d-sm-none">
                                    <div className="text-center mt-3">
                                        <button className="btn btn-dark btn-sm me-2 mb-0" onClick={() => navigate('/registerAccount')}>Register</button>
                                        <button className="btn btn-dark btn-sm me-2 mb-0" onClick={() => navigate('/login')}>Log In</button>
                                        <button className="btn btn-dark btn-sm me-2 mb-0" onClick={() => navigate('/create')}>Create a Character</button>
                                    </div>
                                </div>

                            </>
                        }

                        {props.user &&

                            <>
                                <div className="d-none d-sm-block">
                                    <div className="text-center mt-3">
                                        <button className="btn btn-dark me-2 btn-lg mb-0" onClick={() => navigate('/characters')}>My Characters</button>
                                    </div>
                                </div>

                                <div className="d-block d-sm-none">
                                    <div className="text-center mt-3">
                                        <button className="btn btn-dark btn-sm me-2 mb-0" onClick={() => navigate('/characters')}>My Characters</button>
                                    </div>
                                </div>

                            </>
                        }

                    </div>
                </div>
            </div>

            <div className="row">

                <div className="col-12 col-lg-6 mb-3">
                    <div className="card h-100 border" >
                        <div className="card-body h-100">
                            <h5 className="card-title text-center">ShadowDark RPG</h5>

                            <div className="text-center">
                                <img src={"./ShadowDarkStingbat.png"} style={{ width: "90%", borderRadius: "15px", paddingBottom: "20px" }} alt="Cursed Scroll #1" />
                            </div>

                            <p>Classic gaming with elegant execution.</p>

                            <p><i><a href={SD_URL} target="_blank" rel="noreferrer">Shadowdark RPG</a></i> is what old-school fantasy gaming would look like after being redesigned with 50 years of innovation.</p>

                            <p>The game is fast, familiar, intuitive, and deadly. It’s built for sword-and-sorcery adventures where fell monsters slither in darkness and frosty gems glint upon forgotten thrones, waiting to be discovered.</p>

                            <p>In <i><a href={SD_URL} target="_blank" rel="noreferrer">Shadowdark RPG</a></i>, real time comes into play. A torch only holds back the looming darkness for one hour of actual game time! There isn't a moment to spare when your only light source is guttering low...</p>

                            <p>Get ready for old-school gaming in a whole new light!</p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-6 mb-3">
                    <div className="card h-100 border" >

                        <div className="card-body h-100">
                            <h5 className="card-title text-center">Cursed Scroll #3: Midnight Sun</h5>

                            <div className="text-center">
                                <img src={"./CursedScroll3.png"} style={{ width: "90%", borderRadius: "15px", paddingBottom: "20px" }} alt="Cursed Scroll #3" />
                            </div>

                            <p>Horned helms, bearded axes and dragon-headed longboats... <b><a href={CS3_URL} target="_blank" rel="noreferrer">Cursed Scroll #3: Midnight Sun</a></b> is here!</p>
                            <p>Now supported on ShadowDarklings:</p>
                            <ul className="sourceUL">
                                <li><b>The Sea Wolf!</b> Blood and gold for Odin!</li>
                                <li><b>The Seer!</b> Delve into the secrets of destiny!</li>
                                <li><b>Nord Backgrounds!</b> You are born of the harsh lands of the North!</li>
                                <li><b>Nord Weapons and Armor!</b> Crush your foes with the hand axe, stave and round shield!</li>
                                <li><b>The Old Gods!</b> Make sacrifice to Odin, Freya and Loki!</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-6 mb-3">
                    <div className="card h-100 border" >

                        <div className="card-body h-100">
                            <h5 className="card-title text-center">Cursed Scroll #2: Red Sands</h5>

                            <div className="text-center">
                                <img src={"./CursedScroll2.png"} style={{ width: "90%", borderRadius: "15px", paddingBottom: "20px" }} alt="Cursed Scroll #2" />
                            </div>

                            <p>It's coarse, and rough, and irritating, and it gets everywhere... <b><a href={CS2_URL} target="_blank" rel="noreferrer">Cursed Scroll #2: Red Sands</a></b> is here!</p>
                            <p>Now supported on ShadowDarklings:</p>
                            <ul className="sourceUL">
                                <li><b>The Desert Rider!</b> Fly across the dunes on your trusty steed!</li>
                                <li><b>The Pit Fighter!</b> Laugh at death in the arena!</li>
                                <li><b>The Ras-Godai!</b> Strike from the shadows with the power of the Black Lotus!</li>
                                <li><b>Desert Weapons!</b> Smite the enemy with your razor chain and scimitar!</li>
                            </ul>

                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-6 mb-3">
                    <div className="card h-100 border" >
                        <div className="card-body h-100">
                            <h5 className="card-title text-center">Cursed Scroll #1: Diablerie!</h5>

                            <div className="text-center">
                                <img src={"./CursedScroll1.png"} style={{ width: "90%", borderRadius: "15px", paddingBottom: "20px" }} alt="Cursed Scroll #1" />
                            </div>

                            <p>Something wicked this way comes... as long foretold, <b><a href={CS1_URL} target="_blank" rel="noreferrer">Cursed Scroll #1: Diablerie</a></b> is here!</p>

                            <p>Now supported on ShadowDarklings:</p>

                            <ul className="sourceUL">
                                <li><b>The Knight of Saint Ydris</b>! Fight evil with evil!</li>
                                <li><b>The Witch!</b> Cackle and curse your foes!</li>
                                <li><b>The Warlock!</b> Strike a dangerous deal for untold power!</li>
                                <li><b>Diabolical Backgrounds!</b> Enthrall strangers with your tragic backstory!</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-6 mb-3">
                    <div className="card h-100 border" >
                        <div className="card-body h-100">
                            <h5 className="card-title text-center">Things that passed on the Interwebs</h5>

                            <p><b>Judge James</b> of the <a href="https://www.youtube.com/@Living4Crits" target="blank">Living 4 Crits</a> channel has a walkthrough for creating characters using ShadowDarklings:</p>

                            <div>
                                <iframe className="w-100 " height="300px" src="https://www.youtube.com/embed/Hde157UfuVE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-6 mb-3">
                    <div className="card h-100 border" >

                        <div className="card-body h-100">

                            <h5 className="card-title text-center">The Deceit of Shune the Vile</h5>

                            <div className="row">
                                <div className="Shune mx-auto">
                                    <p>There is no time, there is only rounds. There is no feet, there is only Close/Near/Far. All else is the deceit of Shune the Vile.</p>
                                    <p>A round is a round, be it combat or crawling. The Floating Disk careth not for it dwells in rounds. All else is the deceit of Shune the Vile.</p>
                                    <p>Except for torches, they know about clocks.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default Home;
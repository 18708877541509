import { useEffect, useState } from 'react';

import { User } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import app from '../../firebase/firebase';
import { collection, deleteDoc, doc, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../charGen/spinner';
import { Modal } from 'react-bootstrap';


interface IProps {
    user: User | undefined | null;
}

const Characters: React.FunctionComponent<IProps> = (props: IProps) => {

    const [characters, setCharacters] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [characterId, setCharacterId] = useState("");
    const [charName, setCharName] = useState("");

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getCharacters();
    }, [])

    const navigate = useNavigate();

    const user = props.user;
    const maxChars = parseInt(process.env.REACT_APP_MAX_CHARACTERS === undefined ? "0" : process.env.REACT_APP_MAX_CHARACTERS, 10);

    const getCharacters = async () => {

        if (!user) { navigate("/") }

        try {
            if (user) {
                const db = getFirestore(app);
                const q = query(collection(db, "characterBasic"), where("user_id", "==", user.uid), orderBy("name_lower"));
                setIsLoading(true);
                const querySnapshot = await getDocs(q);

                const characters: any[] = [];
                querySnapshot.forEach((doc) => {
                    characters.push({ ...doc.data(), id: doc.id });
                });
                setCharacters(characters);
                setIsLoading(false);
            }
        }
        catch (error: any) {
            setIsLoading(false);
        }
    }

    const isBlank = (txt: string, def: string): string => txt && txt.trim() !== "" ? txt : def;

    const classAndLevel = (className: string, level: number) => {
        if (className === "Level 0") { return className; }
        if (className.trim() === "") { className = "No Class"; }
        return className + " " + level;
    }

    const getAncestry = (ancestry: string) => {
        if (ancestry.trim() === "") { return "No Ancestry"; }
        return ancestry;
    }

    const confirmDelete = (id: string, charName: string) => {
        setCharacterId(id);
        setCharName(charName);
        handleShow();
    }

    const getCharacterRows = () => {
        const rows: any = [];
        characters.forEach((c) => {
            rows.push(
                <>
                    <div className="row border-bottom w-75 d-none d-sm-block" key={c.id}>
                        <div className="col-md col-sm-12"><Link to={"/create/" + c.id} className="clippedName">{isBlank(c.name, "(No name)")}</Link></div>
                        <div className="col">{classAndLevel(c.className, c.level)}</div>
                        <div className="col">{getAncestry(c.ancestry)}</div>
                        <div className="col-1">
                            <button className="btn btn-dark btn-sm" onClick={(e) => { e.preventDefault(); confirmDelete(c.id, c.name) }} ><FontAwesomeIcon icon={faTrash} title="Delete character"></FontAwesomeIcon ></button>
                        </div>
                    </div>
                </>
            )
        })
        return rows;
    }

    const getCharacterItems = () => {
        const rows: any = [];
        characters.forEach((c) => {
            rows.push(
                <div className="col-12 col-lg-3 col-md-4 col-sm-6 mb-2" key={c.id}>
                    <div className="card h-100 border" >
                        <div className="card-body p-2 m-0">
                            <button className="btn btn-dark btn-sm float-end" onClick={(e) => { e.preventDefault(); confirmDelete(c.id, c.name) }} ><FontAwesomeIcon icon={faTrash} title="Delete character"></FontAwesomeIcon ></button>

                            <div className="d-none d-sm-block">
                                <p className="p-1 m-0"><b><Link to={"/create/" + c.id} className="clippedName">{isBlank(c.name, "(No name)")}</Link></b></p>
                                <p className="p-1 m-0">{classAndLevel(c.className, c.level)}</p>
                                <p className="p-1 m-0">{getAncestry(c.ancestry)} {c.background} </p>
                            </div>

                            <div className="d-sm-none">
                                <p className="p-1 m-0"><b><Link to={"/create/" + c.id} className="clippedName">{isBlank(c.name, "(No name)")}</Link></b></p>
                                <p className="p-1 m-0">{getAncestry(c.ancestry)} {classAndLevel(c.className, c.level)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return rows;
    }

    const deleteCharacter = async (id: string) => {
        setIsLoading(true);
        const db = getFirestore(app);
        await deleteDoc(doc(db, "characterBasic", id));
        await deleteDoc(doc(db, "characterComplete", id));
        await getCharacters();
        setIsLoading(false);
        setCharacterId("");
        setCharName("");
        handleClose();
    }

    const isMaxChars = characters.length >= maxChars;

    return (
        <div>
            <h1>Characters</h1>

            <div className="mb-1 mt-3 container">
                <button className="btn btn-dark btn-lg" onClick={() => navigate('/create')}>Create a Character</button>
                <Spinner isLoading={isLoading} />
                {isMaxChars &&
                    <span className="ms-3">You have reached the maximum of {maxChars} saved characters.</span>
                }

            </div>

            {characters.length > 0 &&
                <div className="container">
                    <div className="row">
                        {getCharacterItems()}
                    </div>
                    <div>{characters.length} character{characters.length === 1 ? "" : "s"} of {maxChars} character limit</div>
                    {/* <div className="small">Sorry about the limit... I want to check how much traffic this gets before I increase the limit.</div> */}
                </div>
            }

            {characters.length === 0 &&
                <div>You currently have no characters.</div>
            }

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm delete character</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you certain that you want to delete {charName}?</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-dark" onClick={handleClose}>
                        Close
                    </button>
                    <button className="btn btn-dark" onClick={() => deleteCharacter(characterId)}>
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>

        </div >
    )
}

export default Characters;
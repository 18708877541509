export interface SourceLink {
    href: string;
    desc: string;
}

export interface Source {
    id: string;
    name: string;
    shortName: string;
    desc: string;
    authors: string;
    links: SourceLink[];
    isOfficial: boolean;
    optional: boolean;
    content: string[];
    backgroundsName?: string;
    isNew?: boolean;
}

export interface activeSourceFilteredAncestry {
    sourceId: string;
    ancestry: string;
    isActive: boolean;
}

export interface activeSourceFilteredClass {
    sourceId: string;
    charClass: string;
    isActive: boolean;
}

export const sources: Source[] = [
    { id: "SD", name: "ShadowDark Core", shortName: "ShadowDark", desc: "The ShadowDark Core Book and Quickstarts", authors: "Kelsey Dionne", links: [{ href: "https://www.thearcanelibrary.com/pages/shadowdark", desc: "ShadowDark Core Book" }, { href: "https://www.drivethrurpg.com/product/413713/Shadowdark-RPG-Quickstart-Set", desc: "Quickstarts" }], content: [], backgroundsName: "Standard", isOfficial: true, optional: false },
    { id: "CS1", name: "Cursed Scroll #1 : Diablerie!", shortName: "Scroll #1", desc: "ShadowDark Zine Vol. 1, introducing the Knight of St. Ydris, Witch and Warlock classes", authors: "Kelsey Dionne", links: [{ href: "https://www.thearcanelibrary.com/products/cursed-scroll-zine-vol-1-diablerie", desc: "Cursed Scroll #1 : Diablerie!" }], content: ["Knight of St. Ydris, Witch, Warlock and Diabolical Backgrounds"], backgroundsName: "Diabolical", isOfficial: true, optional: true, isNew: false },
    { id: "CS2", name: "Cursed Scroll #2 : Red Sands", shortName: "Scroll #2", desc: "ShadowDark Zine Vol. 2, introducing the Desert Rider, Pit Fighter and Ras-Godai classes", authors: "Kelsey Dionne", links: [{ href: "https://www.thearcanelibrary.com/products/cursed-scroll-zine-vol-2-red-sands-pdf-shadowdark-rpg", desc: "Cursed Scroll #2 : Red Sands" }], content: ["Desert Rider, Pit Fighter, Ras-Godai and Desert weapons"], isOfficial: true, optional: true, isNew: false },
    { id: "CS3", name: "Cursed Scroll #3 : Midnight Sun", shortName: "Scroll #3", desc: "ShadowDark Zine Vol. 3, introducing the Sea Wolf and Seer classes", authors: "Kelsey Dionne", links: [{ href: "https://www.thearcanelibrary.com/products/cursed-scroll-zine-vol-3-midnight-sun-pdf-shadowdark-rpg", desc: "Cursed Scroll #3 : Midnight Sun" }], isOfficial: true, content: ["Sea Wolf", "Seer", "Nord Backgrounds and Gear", "Old Gods"], backgroundsName: "Nord", optional: true, isNew: false },
    { id: "SG", name: "Bard, Ranger and Kobold", shortName: "B&R&K", desc: "The Bard and Ranger classes were created by Kelsey as stretch goals for the ShadowDark Kickstarter; the Kobold ancestry was created on the Discord during the Kickstarter campaign", authors: "Kelsey Dionne", links: [{ href: "https://discord.com/channels/558029475837902851/1118657052361891851/1118657052361891851", desc: "Bard and Ranger PDFs" }, { href: "https://discord.com/channels/558029475837902851/821568382952407070/1123000094078271508", desc: "Kobold ancestry on Discord" }], content: ["Kobold ancestry", "Bard and Ranger classes"], isOfficial: true, optional: true },
    { id: "RB", name: "Roustabout", shortName: "Roustabout", desc: "The Roustabout class was created by Michael & Kelsey and released on April 1st 2024", authors: "Michael Putlack & Kelsey Dionne", links: [{ href: "https://www.dropbox.com/scl/fi/mq28912ro6edyr3vs980j/Roustabout.pdf?rlkey=nmslgv0ghzd5rpq3wa349i6p3&e=1&dl=0", desc: "Roustabout" }], isOfficial: true, content: ["Roustabout"], optional: true, isNew: false },
    { id: "US", name: "Unnatural Selection", shortName: "Unnatural Selection", desc: "Unnatural Selection - a ShadowDark expansion. Adds the Chelonian, Dragonborn, Forest Elf, Half Troll, Shadow Elf, Tiefling, Gnome, Mycellan, Risen, Skurrid and Slimefolk ancestries, plus the Beastmaster, Grave Warden, Plague Doctor and Shaman classes", authors: "Dungeon Damsel", links: [{ href: "https://dungeondamsel.com/", desc: "Unnatural Selection" }], content: ["Chelonian, Dragonborn, Forest Elf, Half Troll, Shadow Elf, Tiefling, Gnome, Mycellan, Risen, Slimefolk, Skurrid and Sylvaren ancestries, and the Beastmaster, Fiend, Grave Warden, Ovate, Plague Doctor and Shaman classes"], isOfficial: false, optional: true, isNew: false},
    { id: "DP", name: "Darcy Perry's ShadowDuck & ShadowBark", shortName: "Darcy", desc: "ShadowDuck: introducing the Duck ancestry, Dungeoneer class, Avian language, and Saint Cuddy deity; ShadowBark, introducing the Dog ancestry, Rover class, Canine language, and Cerberpug deity", authors: "Darcy Perry", links: [{ href: "https://starhatminiatures.com/products/shadowdark-duck-ancestry-dungeoneer-class", desc: "ShadowDuck" }, { href: "https://starhatminiatures.com/products/shadowdog-dog-ancestry-rover-class", desc: "ShadowBark" }], content: ["Duck and Dog ancestries"], isOfficial: false, optional: true }
]

export const defaultSources = ["SD", "SG", "CS1", "CS2", "CS3"]; // these sources default to being active when user first visits the site


interface IProps {
}

const Resources: React.FunctionComponent<IProps> = (props: IProps) => {
    return (
        <div>
            <h1 className="mb-3">Resources</h1>

            <div className="twoCols">

                <div>
                    <h2>ShadowDark Game</h2>
                    <ul>
                        <li><a href="https://www.thearcanelibrary.com/pages/shadowdark" target="_blank" rel="noreferrer">ShadowDark</a> roleplaying game</li>
                        <li><a href="https://www.drivethrurpg.com/product/413713/Shadowdark-RPG-Quickstart-Set" target="_blank" rel="noreferrer">ShadowDark Players Quickstart Guide</a></li>
                        <li><a href="https://www.thearcanelibrary.com/products/cursed-scroll-zine-vol-1-diablerie" target="_blank" rel="noreferrer">Cursed Scroll Zine, Vol. 1: Diablerie! </a></li>
                        <li><a href="https://www.thearcanelibrary.com/products/cursed-scroll-zine-vol-2-red-sands-pdf-shadowdark-rpg" target="_blank" rel="noreferrer">Cursed Scroll Zine, Vol. 2: Red Sands </a></li>
                        <li><a href="https://www.thearcanelibrary.com/products/cursed-scroll-zine-vol-3-midnight-sun-pdf-shadowdark-rpg" target="_blank" rel="noreferrer">Cursed Scroll Zine, Vol. 3: Midnight Sun </a></li>
                        <li><a href="https://discord.com/channels/558029475837902851/1118657052361891851/1118657052361891851" target="_blank" rel="noreferrer">Bard and Ranger classes, by Kelsey </a></li>
                        <li><a href="https://discord.com/channels/558029475837902851/821568382952407070/1123000094078271508" target="_blank" rel="noreferrer">Kobold ancestry, by Kelsey </a></li>
                    </ul>
                </div>

                <div>
                    <h2>Forums</h2>
                    <ul>
                        <li><a href="https://discord.gg/thearcanelibrary" target="_blank" rel="noreferrer">ShadowDark Discord</a></li>
                        <li><a href="https://www.reddit.com/r/shadowdark" target="_blank" rel="noreferrer">ShadowDark Reddit</a></li>
                    </ul>
                </div>

                <div>
                    <h2>Character Designers</h2>
                    <ul>
                        <li><a href="https://www.shadowdork.com/" target="_blank" rel="noreferrer">Shadowdork</a></li>
                    </ul>
                </div>

                <div>
                    <h2>VTT Support</h2>
                    <ul>
                        <li><a href="https://foundryvtt.com/packages/shadowdark" target="_blank" rel="noreferrer">ShadowDark module for Foundry VTT</a>: Supports importing characters exported from ShadowDarklings.</li>
                        <li><a href="https://extensions.owlbear.rodeo/sd-character-sheet" target="_blank" rel="noreferrer">ShadowDark character sheet for Owlbear Rodeo 2.0 VTT</a>: Supports importing characters exported from ShadowDarklings. (Also available outside of OBR <a href="https://sd-character-sheet.onrender.com/" target="_blank" rel="noreferrer">here</a>.)  </li>
                        <li><a href="https://github.com/joejames38/Shadowdark_CharacterSheet" target="_blank" rel="noreferrer">ShadowDark character sheet for Roll20 VTT</a>: Does not (so far) support importing characters exported from ShadowDarklings.</li>
                        <li><a href="https://lets-role.com/system/shadowdark-18729" target="_blank" rel="noreferrer">ShadowDark character sheet for Let's Role:</a> Does not (so far) support importing characters exported from ShadowDarklings.</li>
                    </ul>
                </div>

                <div>
                    <h2>Resource Lists</h2>
                    <ul>
                        <li><a href="https://dark-emporium.com/" target="_blank" rel="noreferrer">Dark Emporium</a></li>
                        <li><a href="https://docs.google.com/spreadsheets/d/14KbvKlqmoucIZE9IM49mtSKfLczWEd2tZ5NbP-cQXVk/edit#gid=0" target="_blank" rel="noreferrer">3rd Party Products Master List</a> </li>
                    </ul>
                </div>

                <div>
                    <h2>Various</h2>
                    <ul>
                        <li><a href=" https://shadowdarktools.com/ " target="_blank" rel="noreferrer">ShadowDark Tools</a>: includes encounter, NPC, trap, hazard, and rival generators.</li>
                    </ul>
                </div>

            </div>

        </div>
    )
}

export default Resources;
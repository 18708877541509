import { useNavigate } from 'react-router-dom';

interface IProps {
}

const FAQ: React.FunctionComponent<IProps> = (props: IProps) => {

    const navigate = useNavigate();

    return (
        <div>
            <h1>FAQ</h1>

            <div className="twoCols">

                <div>
                    <div className="faqHead">So what's this then?</div>
                    <div className="faqBody">This is a character generator based on the <a href="https://www.thearcanelibrary.com/pages/shadowdark" target="_blank" rel="noreferrer">ShadowDark Roleplaying Game</a>, plus content from the Cursed Scrolls 'zines (

                        <a href="https://www.thearcanelibrary.com/products/cursed-scroll-zine-vol-1-diablerie" target="_blank" rel="noreferrer">#1</a>,&nbsp;
                        <a href="https://www.thearcanelibrary.com/products/cursed-scroll-zine-vol-2-red-sands-pdf-shadowdark-rpg" target="_blank" rel="noreferrer">#2</a>,&nbsp;and&nbsp;
                        <a href="https://www.thearcanelibrary.com/products/cursed-scroll-zine-vol-3-midnight-sun-pdf-shadowdark-rpg" target="_blank" rel="noreferrer">#3</a>) and some of Kelsey's extras (
                        <a href="https://discord.com/channels/558029475837902851/1118657052361891851/1118657052361891851" target="_blank" rel="noreferrer">Bard and Ranger</a> and the&nbsp;
                        <a href="https://discord.com/channels/558029475837902851/821568382952407070/1123000094078271508" target="_blank" rel="noreferrer">Kobold ancestry</a>).

                    </div>
                    <div className="faqBody">This site also contains material from some 3rd party creators, with the kind support of the Arcane Library.</div>

                </div>
                <div>
                    <div className="faqHead">It this official?</div>
                    {/* <div className="faqBody">No, this is a fan-made project, but the author of ShadowDark, Kelsey Dionne, has given kind permission to use the content from the Core Book, as well as selected content from the Cursed Scrolls. A written license specifically for ShadowDarklings is in the works.</div> */}
                    <div className="faqBody">Yes - this site is owned by the <a href="https://www.thearcanelibrary.com/" target='_blank' rel="noreferrer">Arcane Library</a>, which publishes the Shadowdark RPG.</div>
                </div>
                {/* <div>
                <div className="faqHead">Where can I get the Quickstart from?</div>
                <div className="faqBody">You can get a free copy here: <a href="https://www.drivethrurpg.com/product/413713/Shadowdark-RPG-Quickstart-Set" target="_blank" rel="noreferrer">Quickstart Guide</a></div>
            </div> */}
                <div>
                    <div className="faqHead">What other sites are out there that do this sort of stuff?</div>
                    <div className="faqBody">Check out <a href="https://www.shadowdork.com/" target="_blank" rel="noreferrer">www.shadowdork.com</a></div>
                </div>
                <div>
                    <div className="faqHead">How can I contact you with bug reports or feature requests?</div>
                    <div className="faqBody">Check out the <a href="#!" onClick={() => navigate('/contact')} rel="noreferrer">Contact page</a>.</div>
                </div>

            </div>
        </div>
    )
}

export default FAQ;